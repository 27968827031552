import React from "react";
import PortfolioContainer from "./components/PortfolioContainer";



const App = () => {
  return (
    <PortfolioContainer />
  );
};

export default App;
